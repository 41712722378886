import {combineReducers} from 'redux';
import * as actionTypes from '../actions/types';
import moment from 'moment';

const initialUserState = {
    currentUser : null, 
    isLoading : true,
    licenseStatus : 'TRIAL',
    daysLeft : 15,
    week: false,
    date: moment()    
};

const initialComputersState = {
    currentComputer : null, 
    computers : []
};

const user_reducer = (state  = initialUserState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER: 
            return {
                ...state,
                currentUser: action.payload.currentUser, 
                isLoading : false, 
            }

        case actionTypes.CLEAR_USER: 
            return {
                ...state,
                currentUser: null, 
                isLoading : false, 
            }  
            
            case actionTypes.SET_LICENSE_STATUS: 
            return {
                ...state,
                licenseStatus: action.payload.licenseStatus, 
                daysLeft : action.payload.daysLeft,                
            }             

        case actionTypes.SET_WEEK:
            return { 
                ...state, 
                week: action.payload 
            };
            
        case actionTypes.SET_DATE:
            return { ...state, date: action.payload };            

        default: 
            return state;

    }
}

const computers_reducer = (state  = initialComputersState, action) => {
    switch (action.type) {
        case actionTypes.SET_COMPUTERS: 
            return {
                ...state,
                computers: action.payload.computers, 
                isLoading : false, 
            }

        case actionTypes.SET_CURRENT_COMPUTER: 
            const currentComputer = state.computers.length > 0 ? action.payload.currentComputer : null
            return {
                ...state,
                currentComputer, 
                version : action.payload.version
            }            

        case actionTypes.COPY_SETTINGS:
            return {
                ...state,
                settings: action.payload.settings,
                settingsFrom : action.payload.name
            }

        default: 
            return state;

    }
}

const rootReducer = combineReducers({
    user : user_reducer,
    computers : computers_reducer,
})

export default rootReducer;
