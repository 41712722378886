import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import withUID from '../withUID';
import './dashboard.css';
import Status from './Status';
import { changeStatus } from '../../Firebase/applySettings';

import DashboardComputerTime from './DashboardComputerTime';
import DashboardComputerTime_v2 from './DashboardComputerTime_v2';
import DashboardAppChart from './DashboardAppChartWeek';
import DashboardWebChart from './DashboardWebChartWeek';
import DashboardAlerts from './DashboardAlerts';
import DashboardComputerTimeByHours from './DashboardComputerTimeByHours';
import DashboardChart from './DashboardChart';
import DatePicker_2023 from '../UI/DatePicker_2023';
import { Icon, Label } from 'semantic-ui-react';
import moment from 'moment';
import { setWeek, setDate } from '../../Store/actions';
import { connect } from 'react-redux';
import DeleteKeys from './Delete';


function Dashboard({uid, currentComputer, version, date, week, setWeek, setDate}) {
  
  const [settings, setSettings] = useState({status: 'active', bypassed: '0'});
  const [dates, setDates] = useState([]);   
  const [lastUpdate, setLastUpdate] = useState(moment()); 

  const datesRef = firebase.database().ref(`${uid}/${currentComputer}/reports/applications/dates`);  

  useEffect(() => {
    if (!currentComputer) return;

    const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/systemInfo`);
    settingsRef.on('value',
      // success
      (snapshot) => {
        const newSettings = snapshot.val() || {};
        setSettings(newSettings);
      },
      // error
      (error) => {
        console.log(error);
      }
    );

    return () => {
      if (settingsRef) settingsRef.off();
    };
  }, [uid, currentComputer]);


  useEffect(() => {
    if (!currentComputer) return;  
    datesRef.once('value', 
        (snapshot) => { // success
            const dates = Object.keys(snapshot.val() || {}).map(date => moment(date, 'YYMMDD').toDate());  ;
            setDates(dates);
    },        
    (error) => { // error
      console.log(error);
    });
    }, [uid, currentComputer, lastUpdate]);  

  const handleChangeDate = (date) => {
    const newDate = date;
    setDate(newDate);
  }

  const handlePreviousDay = () => {
    const previousDay = moment(date).subtract(week ? 7 : 1, 'day');
    setDate(previousDay);
  };

  const handleNextDay = () => {
    const nextDay = moment(date).add(week ? 7 : 1, 'day');
    setDate(nextDay);
  };

  const handleChangeStatus = () => {
    const { status } = settings;
    if (status === 'expired') return;
    const newStatus = status === 'active' ? 'inactive' : 'active';
    setSettings({status : newStatus, bypassed : '0'})
    changeStatus(uid, currentComputer, newStatus);
  };

  const { status = 'active', bypassed = '0' } = settings;

  const weekStart = moment(date).startOf('isoWeek');
  const weekEnd = moment(date).endOf('isoWeek');  
  const formatedDate = !week ? moment(date).format('MMM DD, YYYY') : `${weekStart.format('MMM DD, YYYY')} - ${weekEnd.format('MMM DD, YYYY')}`;
  const formatedLastUpdate = moment(lastUpdate).format('LTS');

  return (
    <div className="dashboard">  
      <Status status={status} bypassed={bypassed} onClick={handleChangeStatus} />
      <h2 style={{marginBottom : 0, marginLeft: 20, marginTop : 20}}>
            <span className='date-box' style={{width: '100%', textAlign : 'right'}}>                
              <span className={`tab-button day ${!week ? 'active' : ''}`} onClick={() => setWeek(false)}>DAY</span>            
              <span className={`tab-button week ${week ? 'active' : ''}`} onClick={() => setWeek(true)}>WEEK</span>                                                                                                
              <Icon className='date-change' style={{marginLeft: 0}} name='angle left' onClick={handlePreviousDay} />                   
              <Icon className='date-change' name='angle right' onClick={handleNextDay} />        
              <DatePicker_2023 date={moment(date)} includes={dates} onChangeDate={handleChangeDate}/>   
              <span style={{marginLeft : 5, fontSize: '0.9em'}}>{formatedDate}</span>              
              <Label title="refresh" as='a' style={{marginLeft : 20, top : -5}} onClick={() => setLastUpdate(moment())}>
                <Icon name='refresh' className='date-change' />  
                {formatedLastUpdate}
              </Label>              
            </span>           
      </h2>	              
      <div style={{ display: 'flex', flexDirection: 'row', flexFlow: 'row wrap', justifyContent: 'left' }}>
        <DashboardChart dates={dates} lastUpdate={lastUpdate} week={week} date={date} key={date + week + 'chart'}/>
        <DashboardAlerts />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flexFlow: 'row wrap', justifyContent: 'left' }}>
        <DashboardAppChart dates={dates} week={week} key={date + week + 'app'} date={date}/>
        <DashboardWebChart dates={dates} week={week} key={date + week + 'web'} date={date}/>
      </div>              
        <div style={{display : 'flex', flexDirection : 'row', flexFlow: 'row wrap', justifyContent: 'left' }}>
            {version >= 6201 ? <DashboardComputerTime_v2 lastUpdate={lastUpdate} week={week} date={date}/> : <DashboardComputerTime lastUpdate={lastUpdate} week={week} date={date}/>}
            {version >= 6201 && !week && <DashboardComputerTimeByHours lastUpdate={lastUpdate} date={date}/>}                
        </div>  
      {//  <DeleteKeys/>              
}
    </div>        
  )
}

const mapStateToProps = (state) => ({
  week: state.user.week,
  date: state.user.date
});

const mapDispatchToProps = {
  setWeek,
  setDate
};

export default connect(mapStateToProps, mapDispatchToProps)(withUID(Dashboard));
