import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import moment from 'moment';
import withUID from './../withUID';
import AppIcon from '../UI/AppIcon';
import { secondsToString } from '../../utils';
import { Message } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

const DashboardAppChart = (props) => {
    const [items, setItems] = useState([]);
    const date = moment(props.date).format('YYMMDD');  

    const {uid, currentComputer, week, dates} = props;

    const startDate = moment(props.date).startOf('isoWeek');
    const days = !week ? [date] :  Array.from({ length: 7 }, (_, i) => (moment(startDate).add(i, 'days').format('YYMMDD'))); 

    useEffect(() => {
        if (!currentComputer) return; 
        const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/applications`);                  
        days.forEach(date => { 
            const dateExists = dates.some(d => moment(d).format('YYMMDD') === date);                
            if (dateExists) {                     
                ref.child(date).once('value',
                    // success
                    (snapshot) => {
                        const items = Object.values(snapshot.val() || {});
                        setItems(prevItems => ({ ...prevItems, [date]: items }));
                    },
                    // error
                    (error) => {
                        console.log(error);
                    }
                );
            }
        });

    }, [uid, currentComputer, date]);  

    const groupedData = _.groupBy(_.flatten(Object.values(items)), 'name');
    const itemsWeek = _.map(groupedData, (items, name) => ({
        name,
        time: _.sumBy(items, item => Number(item.time))
      }));
    itemsWeek.sort((a,b) => b.time - a.time);   
    const topItemsWeek = itemsWeek.slice(0, 5);   
      
    const chart = (topItemsWeek || []).map(({ name, time }) => (
        <div className="app-item" key={name}>
            <AppIcon name={name} />
            <div className="app-time">{secondsToString(time)}</div>
        </div>
    ));

    return (
        <div style={{marginRight: 50}}>
            <h3>Top Apps
                <div className="app-link"><NavLink exact to={"/applications"} className="link active">[ See more ]</NavLink></div>                        
                <div className="app-link"><NavLink exact to={"/application_filter"} className="link active">[ Adjust limits ]</NavLink></div>                        
            </h3>
            <div className="app-chart">
                {chart.length > 0 ? chart : <Message style={{ marginBottom: 30 }} warning content="No activity for this date" />}
            </div>
        </div>
    );
};

export default withUID(DashboardAppChart);
