import React, { Component } from 'react'
import firebase from '../../firebase';
import {Checkbox, Input} from 'semantic-ui-react';
import withUID from './../withUID';
import ApplyButton from './../UI/ApplyButton';
import { applySettings } from './../../Firebase/applySettings';
import ToolTip from '../UI/ToolTip';

class GeneralSettings extends Component {
    state = {
        settingsRef : null,              
        settings : {
            applications : true,
            websites : true, 
            computerTime : true,
            queries : true,
            showStartMessage : false,
            startMessage : "Productivity management software is active on this computer.",
            showFilterWarning : true, 
            redirectTo : '',
            idleTimeout : 5,
            showTimer : true,
        }, 
        data : null     
    }

    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);                
        this.setState({settingsRef});
        settingsRef.child('generalSettings').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }

    componentDidMount() {
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }  
    
    componentWillUnmount() {
        if (this.state.data) {
            this.handleSaveSettings();
        }
    }

    handleSaveSettings = () => {        
        this.state.settingsRef.child("generalSettings").update(this.state.data);        
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		        
    }

    onChange = (name, value) => {            
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value}
        }));        		

    }
    
    render() {		
        const {version} = this.props;
        const {showFilterWarning, showTimer, redirectTo} = this.state.settings;

        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/> 
				<h2>General Settings</h2>	                                
                <h3>Blocking Options</h3>				
                <div style = {{padding : 10}}>
                    <Checkbox label="Show warning on blocking " checked={showFilterWarning} onChange={(e, data) => this.onChange("showFilterWarning", data.checked)}/><ToolTip text='Enable this option to display a warning message to your user when an activity has been blocked.'/>
                </div>                
                {version >= 8201&&<div style = {{padding : 10}}>                    
                    <Checkbox label="Show remaining time window" checked={showTimer} onChange={(e, data) => this.onChange("showTimer", data.checked)}/>                    
                </div>} 
                <div style ={{padding : 10, paddingTop: 0, maxWidth : "550px", display : 'flex', flexDirection : 'row', alignItems : "center"}}>
                    <div style={{marginRight: 10}}>Redirect banned websites to</div>
                    <Input style={{flexGrow: 2}} value={redirectTo} placeholder="about:blank" onChange={(e, data) => this.onChange("redirectTo", data.value)}/>    
                    <ToolTip text="Enter a URL to redirect users to a specific page when they attempt to access a banned website."/>
                </div>
            </>
        )
    }
}

export default withUID(GeneralSettings);
