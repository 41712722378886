import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import withUID from './../withUID';
import { secondsToString } from '../../utils';

  
const ChartComponent = ({data}) => {
const yAxisLabels = ['60m', '40m', '20m', '0m'];

const overlayLines = Array.from({ length: 3 }, (_, i) => (
    <div key={i} className="line-overlay" style={{top : `${100 * (i / 3)}%`}}></div>
));

const hoursLabels = [0, 3, 6, 9, 12, 15, 18, 21];

const chartBars = data.map((value, index) => { 
    return (
        <div key={index} className="chart-bar" style={{margin: 0}}>
            <div
                title={secondsToString(Math.round(value * 36))}
                className="chart-bar-segment chart-bar-active"
                style={{backgroundColor: '#72a2c6', height: `${Math.max(value, 0.5)}%` }}
            ></div>
            {hoursLabels.includes(index)&&<div className="chart-bar-label">{`${index.toString().padStart(2, '0')}:00`}</div>}
        </div>
    );
    });

return (
    <div className="chartComputer" style = {{width: '100%', marginBottom: 20, height : 200}}>
    <div className="chart-y-axis" style={{width: 40}}>
        {yAxisLabels.map((label, index) => (
        <div key={index} className="chart-y-axis-label" style={{position: "absolute", top: `${100 * (index / 3)}%`}}>
            {label}
        </div>
        ))}
    </div>
    {chartBars}
    <div className="chart-overlay" style={{height: 148, left : 55, width : '93%'}}>{overlayLines}</div>        
    </div>
);
};

const ComputerTimeByHours = ({uid, currentComputer, user, date}) => {
    const [computerTime, setComputerTime] = useState([]);

    useEffect(() => {
        if (!currentComputer) return;
        const timeRef = firebase.database().ref(`${uid}/${currentComputer}/reports/timeByHours/${user}/${date}`);
        timeRef.on('value',
            // success
            (snapshot) => {
                const raw = snapshot.val() || {};                   
                const computerTime = Array.from({ length: 24 }, (_, i) => {
                    const hour = i < 10 ? '0' + i : i;
                    return (raw[hour] || 0) / 3600 * 100;                              
                });                             
                setComputerTime(computerTime);
            },
            // error
            (error) => {
                console.log(error);
            });       

        return () => {
            if (timeRef) timeRef.off();
        }
    }, [uid, currentComputer, user, date]);

    return (
        <ChartComponent data={computerTime} />         
    )
}

export default withUID(ComputerTimeByHours);