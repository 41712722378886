import React from 'react';
import screen1 from '../../images/httv-download.png';
import screen2 from '../../images/httv-install.png';
import screen3 from '../../images/httv-signup.png';
import {Icon} from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const Download = () => {
    return(
        <div className="downloadPage">
            <div className="center">
                <h1>Download and install the HT Task Vector desktop app on your computer</h1>                
            </div>
            <div className="center">
                <a className="button" href="https://downloads.ht-vector.com/task_vector_setup.exe"  title="Download HT Task Vector"><Icon name="windows"/> Download HT Task Vector</a>
            </div>            
            <div className="center">
                <p className="text">If you are on another device right now, use the link below for downloading:</p>
            </div>
            <div className="center">
                <p className="link">ht-vector.com/download-task-vector</p> 
            </div>
            
            <div className="screenshots">
                <div className="step">
                    <img src={screen1} alt="Download HT Task Vector"/>
                    <p className="description">Download the app on your or your team's computers.</p>    
                </div>                
                <div className="step">
                    <img src={screen2} alt="Install HT Task Vector"/>
                    <p className="description">Run task_vector_setup.exe to install.</p>
                </div>                    
                <div className="step">
                    <img src={screen3} alt="Use HT Task Vector"/>
                    <p className="description">Log in to the app with your account.</p>                
                </div>                    
            </div>               
        </div>
    )
}

const DownloadPage = props => {
    return (     
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogTitle>
                <div style={{position: "absolute", top: "8px", right: "10px"}}>
                    <IconButton aria-label="close" onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Download/>
            </DialogContent>              
          </Dialog>
      )
  }

export default DownloadPage;