import React from 'react';

const ToDoList = () => (    
    <div className='todolist'>
        <div className='todolist-container'>
            <div className='todolist-item'>
                <div className='todolist-number'>1</div>
                <h2>Sign up</h2>
                <p className='todolist-description'>Create your HT Task Vector account.</p>
            </div>
            <div className='todolist-item'>
                <div className='todolist-number'>2</div>
                <h2>Download and install</h2>
                <p className='todolist-description'>
                    <a href="https://downloads.ht-vector.com/task_vector_setup.exe" className='todolist-link'>
                        Download
                    </a> and install the <strong>HT Task Vector</strong> app on your team's devices to track and manage their time. After installation, log in to your account.
                </p>
            </div>
            <div className='todolist-item'>
                <div className='todolist-number'>3</div>                 
                <h2>Use your online dashboard</h2>
                <p className='todolist-description'>Access your dashboard via any browser to view reports, set usage rules, and block distractions in real-time.</p>
            </div>
        </div>
    </div>
);

export default ToDoList;