import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: "AIzaSyCFM4aRrmb-qJvA37K493uST0k3OG_NoXw",
  authDomain: "task-focus-1c8ed.firebaseapp.com",
  databaseURL: "https://task-focus-1c8ed.firebaseio.com",
  projectId: "task-focus-1c8ed",
  storageBucket: "task-focus-1c8ed.appspot.com",
  messagingSenderId: "300302999566",
  appId: "1:300302999566:web:41d521481108882bd3dc5f"
};

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;