import React, { Component } from 'react'
import firebase from '../../firebase';
import ReportTable from '../UI/ReportTable';
import {Table, Checkbox, Icon} from 'semantic-ui-react';
import withUID from './../withUID';
import ApplyButton from './../UI/ApplyButton';
import { applySettings } from './../../Firebase/applySettings';

class UserItem extends Component {  
    onChange = (name, value) => {
        const newItem = Object.assign({}, this.props.item);
        newItem[name] = value;
        this.props.onChange(newItem);        
    }

    render () {
        const {name, checkbox1, checkbox2, checkbox3, checkbox4} = this.props.item;
        return (
            <>
            <Table.Cell><Icon name='user' style={{float : 'none', marginRight : '0.5rem', color: 'rgb(0,0,0, 0.6)'}}/>{name}</Table.Cell>
            <Table.Cell className="center"><Checkbox toggle onChange={(e, data) => this.onChange('checkbox1', data.checked)} checked={checkbox1}/></Table.Cell>
            <Table.Cell className="center"><Checkbox toggle onChange={(e, data) => this.onChange('checkbox2', data.checked)} checked={checkbox2}/></Table.Cell>
            <Table.Cell className="center"><Checkbox toggle onChange={(e, data) => this.onChange('checkbox3', data.checked)} checked={checkbox3}/></Table.Cell>
            <Table.Cell className="center"><Checkbox toggle onChange={(e, data) => this.onChange('checkbox4', data.checked)} checked={checkbox4}/></Table.Cell>                                    
            </>
        )
    }
}

const columns= [
    { title : 'Username', style : {maxWidth: '400px'}}, 
    { title : 'Reports', className : ''},
    { title : 'Time Management', className : ''},
    { title : 'Website Blocking', className : ''},
    { title : 'Enhanced Security', className : ''},
    ];         

class UserSettings_old extends Component {
    state = {
        settingsRef : null,                 
        settings : {},          
        data : null, // updated settings
        users : []
    }

    getUsers = (uid, currentComputer) => {        
        const usersRef = firebase.database().ref(`${uid}/${currentComputer}/users`);
        usersRef.once('value',
        // success
        (snapshot) => {
            const raw = snapshot.val() || {};
            const users = Object.values(raw);                
            this.setState({users});                        
          },
        // error
        (error) => {
            console.log(error);            
        });
    }       
    
    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);            
        this.setState({settingsRef});
        settingsRef.child('userSettings').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }      

    componentDidMount() {
        if (this.props.currentComputer) this.getUsers(this.props.uid, this.props.currentComputer);            
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }    

    componentWillUnmount() {
        if (this.state.data) {
            this.handleSaveSettings();
        }
    }    
    
    handleSaveSettings = () => {                
        this.state.settingsRef.child("userSettings").update(this.state.data);        
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		
    }

    onChange = (name, value) => {             
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value}
        }));        
    }

    render() {	                
        const settings = this.state.users.map(item => {
            const userSettings = this.state.settings[item];            
            if (userSettings) {
                return (
                    {
                        name : item,
                        checkbox1 : userSettings.checkbox1 || false,
                        checkbox2 : userSettings.checkbox2 || false,
                        checkbox3 : userSettings.checkbox3 || false,
                        checkbox4 : userSettings.checkbox4 || false,
                        checkbox5 : userSettings.checkbox5 || false,                                                                                
                    }
                )
            }  else {
                return (
                    {
                        name : item,
                        checkbox1 : true,
                        checkbox2 : true,
                        checkbox3 : true,
                        checkbox4 : true,
                        checkbox5 : true,                                                                                
                    }
                )
            }                
        });	
        
      
        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/> 
				<h2>User Settings
                    <a href='https://ht-vector.com/ht-task-vector-how-to-use#users' rel="noopener noreferrer" target="_blank"><Icon name='question circle' className="help-icon"/></a>
                </h2>	                
    		    <div style={{maxWidth: '1000px', padding: 10}}>							    
            	    <ReportTable unstackable={true} collumns={columns} list={settings}>
            		{ (index, item) => <UserItem item={item} onChange={value => this.onChange(item.name, value)}/>}
            	    </ReportTable>
        	    </div>   
                            
            </>
        )
    }
}

export default withUID(UserSettings_old);
