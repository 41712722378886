import React, { Component } from 'react'
import firebase from '../../firebase';
import {Checkbox} from 'semantic-ui-react';
import withUID from './../withUID';
import ApplyButton from './../UI/ApplyButton';
import Slider from '../UI/Slider';
import {minutesToString} from '../../utils';
import { applySettings } from './../../Firebase/applySettings';
import TrackingSchedule from '../UI/TrackingSchedule';
import ToolTip from '../UI/ToolTip';

class GeneralSettings extends Component {
    state = {
        settingsRef : null,              
        settings : {
            applications : true,
            websites : true, 
            computerTime : true,
            queries : true,
            showStartMessage : false,
            startMessage : "System administration software is currently working on this computer.",
            showFilterWarning : true, 
            redirectTo : '',
            idleTimeout : 5,
        }, 
        data : null     
    }

    checkboxes = [
        { name : 'applications', label : 'Record applications'},
        { name : 'websites', label : 'Record websites'},                
        { name : 'queries', label : 'Record search queries'},   
        { name : 'computerTime', label : 'Record computer time'},             
    ]

    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);                
        this.setState({settingsRef});
        settingsRef.child('generalSettings').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }

    componentDidMount() {
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }  
    
    componentWillUnmount() {
        if (this.state.data) {
            this.handleSaveSettings();
        }
    }

    handleSaveSettings = () => {        
        this.state.settingsRef.child("generalSettings").update(this.state.data);        
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		        
    }

    onChange = (name, value) => {            
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value}
        }));        		

    }
    
    render() {		
        const {version} = this.props;           
        const {trackingSchedule , allowPrivateTime = false, idleTimeout = 1} = this.state.settings;
        const monitoringSettings = this.checkboxes.map((checkbox) => {
            return(
                <div key={checkbox.name} style = {{padding : 10}}>
                    <Checkbox onChange={(e, data) => this.onChange(checkbox.name, data.checked)} checked={this.state.settings[checkbox.name]} label={checkbox.label}/>		
                </div>
            )
        });

        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/> 
				<h2>Tracking Settings</h2>                                               
                {monitoringSettings}
                <div style ={{padding : 10, maxWidth : "550px"}}>
                    <div style={{marginRight: 10, marginTop : 0}}>
                        Idle Timeout
                        <ToolTip text="The amount of time after which idle time begins."/>  
                    </div>
                    <div style={{padding : 10}}>
                        <Slider value={idleTimeout} min={1} max={10} onChange={(e, value) => this.onChange('idleTimeout', value)}/>
                    </div>                        
                    <div style={{padding : "10px", paddingTop: "0"}}>
                        {minutesToString(idleTimeout)}                                
                    </div>                 
                </div>  
                {version >= 6201 && <><div style = {{padding : 10}}>
                    <Checkbox label="Allow user to pause tracking (Private Time)" checked={allowPrivateTime} onChange={(e, data) => this.onChange("allowPrivateTime", data.checked)}/>
                    <ToolTip text="This feature enables users pause/resume tracking either manually or through a timer, with convenience of reminder alerts. This can be done by clicking the app tray icon on their computers."/>
                </div> 
                <TrackingSchedule schedule={trackingSchedule} onChange={trackingSchedule => this.onChange('trackingSchedule', trackingSchedule)}/></>}
                                             
            </>
        )
    }
}

export default withUID(GeneralSettings);
