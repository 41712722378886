import React from 'react';
import {Icon, Menu, Dropdown} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';
import { copyrights, privacyPolicyLink, termsLink } from '../consts';
import withUID from '../withUID';

const itemsV4 = [
  {name:'dashboard', icon:'home', to:'/', label:'Dashboard'},
  {title:'Rules'},
  {name:'timemanagement', icon:'history', to:'/time_management', label:'Time Management'},
  {name:'websiteblocking', icon:'minus circle', to:'/website_blocking', label:'Website Blocking'},
  {name:'windowssecurity', icon:'shield alternate', to:'/windows_security', label:'Enhanced Security'},
  {title:'Reports'},
  {name:'application', icon:'window restore outline', to:'/applications', label:'Applications'},
  {name:'webistes', icon:'globe', to:'/websites', label:'Websites'},
  {name:'computertime', icon:'time', to:'/computer_time', label:'Timesheets'},
  {name:'searchqueries', icon:'search', to:'/search_queries', label:'Search Queries'},  
  {name:'alerts', icon:'warning', to:'/alerts', label:'Alerts'},
  {title:'Settings'},
  {name:'trackingettings', icon:'options', to:'/tracking_settings', label:'Tracking Settings'},    
  {name:'generalsettings', icon:'setting', to:'/general_settings', label:'General Settings'},
  {name:'usersettings', icon:'user', to:'/user_settings', label:'User Settings'}
];

const itemsV5 = [
  {name:'dashboard', icon:'home', to:'/', label:'Dashboard'},
  {title:'Rules'},  
  {name:'websitelimits', icon:'filter', to:'/website_filter', label:'Website Limits'},  
  {name:'websiteblocking', icon:'minus circle', to:'/website_blocking', label:'Website Blocking'},
  {name:'applimits', icon:'lock', to:'/application_filter', label:'App Limits'},  
  {name:'windowssecurity', icon:'shield alternate', to:'/windows_security', label:'Enhanced Security'},
  {title:'Reports'},
  {name:'application', icon:'window restore outline', to:'/applications', label:'Applications'},
  {name:'webistes', icon:'globe', to:'/websites', label:'Websites'},
  {name:'computertime', icon:'time', to:'/computer_time', label:'Timesheets'},
  {name:'searchqueries', icon:'search', to:'/search_queries', label:'Search Queries'},  
  {name:'alerts', icon:'warning', to:'/alerts', label:'Alerts'},
  {title:'Settings'},
  {name:'trackingettings', icon:'options', to:'/tracking_settings', label:'Tracking Settings'},  
  {name:'generalsettings', icon:'setting', to:'/general_settings', label:'General Settings'},
  {name:'usersettings', icon:'user', to:'/user_settings', label:'User Settings'}
];

const MenuItem = ({name, icon, label, to}) => {
  return (
    <Menu.Item key={name} name={name} as='h3' style={{paddingLeft: 10, paddingBottom : 7}} className="sidebar-link">
    <Icon name={icon} style={{float : 'none', marginRight : '0.9rem', fontSize: '0.8em', color: 'rgb(0,0,0, 0.7)'}}/>
    <NavLink exact to={to} className="link" style={{fontSize : '0.9em'}} activeClassName="active">{label} </NavLink>
  </Menu.Item>
  )
}


const CollapsedComputerMenu  = ({items}) => {
  const menu = items.map(item => {
    if (item.title) return <Dropdown.Header className="sidebar-title">{item.title}</Dropdown.Header>  
    return <MenuItem name={item.name} icon={item.icon} to={item.to} label={item.label}/>
  })   

  return(
    <div style={{zIndex: 10000, position: "fixed", marginTop: "5px", top: "5px", marginRight: 10, right: "15px"}}>
      <Dropdown       
        icon='bars'         
        button
        direction='left'      
        className='icon sidebar-icon'    
    >
        <Dropdown.Menu>
          {menu}
        </Dropdown.Menu>
      </Dropdown>
    </div> 
    )
  }

const ComputerMenu = (props) => {
  const {version} = props; 
  const items = version >= 5101 ? itemsV5 : itemsV4;

  if (props.collapsed) return <CollapsedComputerMenu items={items}/>

  const menu = items.map(item => {
    if (item.title) return <h2 key={item.title} className="sidebar-title" style={{marginTop : 5, marginBottom : 5}}>{item.title}</h2>
    return <MenuItem key={item.name} name={item.name} icon={item.icon} to={item.to} label={item.label}/>
  })

    return (      
      <div>      
        <Menu secondary fluid vertical>
          {menu}
        </Menu>
        <div>
          <a style={{color : "#555"}} target="_blank" rel="noopener noreferrer" href={termsLink}>Terms</a>
          <a style={{marginLeft : 10, color : "#555"}} target="_blank" rel="noopener noreferrer" href={privacyPolicyLink}>Privacy Policy</a>
        </div>    
        <div style={{marginBottom : 10, color : "#666"}}>{copyrights}</div>
      </div>    
    )
  }

 

  export default withUID(ComputerMenu);