import React from 'react';
import {Icon} from 'semantic-ui-react';
export const mainTitle = 'HT Task Vector'
export const version = '6.3.1'
export const howToUseLink = 'https://ht-vector.com/ht-task-vector-how-to-use'
export const helpLink = 'https://ht-vector.com/feedback.php'
export const privacyPolicyLink = 'https://ht-vector.com/privacy-policy'
export const subscribeLink = 'https://ht-vector.com/ht-task-vector-pricing'
export const termsLink = 'https://ht-vector.com/terms'
export const homepageLink = 'https://manage.ht-vector.com/'
export const copyrights = '© 2024 HT Vector'

export const statuses = [
	{
		value : 0,
		label : 'Allow',
		className : 'allow'
	}, 
	{
		value : 1,
		label : 'Limit',
		className : 'limit'
	},
	{
		value : 2, 
		label : 'Block',
		className : 'block'
	},
	];

export const time_limits = [
{ value : 0, label : "15 minutes per day",}, 
{ value : 1, label : "30 minutes per day",}, 
{ value : 2, label : "45 minutes per day",}, 
{ value : 3, label : "1 hour per day",}, 
{ value : 4, label : "1.5 hours per day",}, 
{ value : 5, label : "2 hours per day",}, 
{ value : 6, label : "2.5 hours per day",}, 
{ value : 7, label : "3 hours per day",}, 
{ value : 8, label : "4 hours per day",}, 
{ value : 9, label : "5 hours per day",}, 
{ value : 10, label : "6 hours per day",}, 
{ value : 11, label : "7 hours per day",}, 
{ value : 12, label : "Unlimited",}, 
];

export const weekdays = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
 ];

 export const hours = [
{ value : 0, label: "12" },
{ value : 1, label: "1" },
{ value : 2, label: "2" },
{ value : 3, label: "3" },
{ value : 4, label: "4" },
{ value : 5, label: "5" },
{ value : 6, label: "6" },
{ value : 7, label: "7" },
{ value : 8, label: "8" },
{ value : 9, label: "9" },
{ value : 10, label: "10" },
{ value : 11, label: "11" },
{ value : 12, label: "12" },
{ value : 13, label: "1" },
{ value : 14, label: "2" },
{ value : 15, label: "3" },
{ value : 16, label: "4" },
{ value : 17, label: "5" },
{ value : 18, label: "6" },
{ value : 19, label: "7" },
{ value : 20, label: "8" },
{ value : 21, label: "9" },
{ value : 22, label: "10" },
{ value : 23, label: "11" },
{ value : 24, label: "12" },
 ];

 export const modeOptions = [
	{ 
		value : 'hour',
		text : <span>per hour</span>,
		multiplier : 0.5,		
	  },
	{ 
		value : 'day',
		text : <span>per day</span>,
		multiplier : 5		
	},
	{ 
		value : 'week',
		text : <span>per week</span>,
		multiplier : 35
	  }
 ];

 export const modeOptions2 = [
		{ 
			value : 'hour',
			text : <span>per hour</span>,
			multiplier : 0.5,		
		  },
		{ 
			value : 'day',
			text : <span>per day</span>,
			multiplier : 5		
		},
		{ 
			value : 'week',
			text : <span>per week</span>,
			multiplier : 35
		  }
	 ];
 

 export const warningOptions = () => [
	{ 
	  value : 5,
	  text : <span>5 minutes</span>
	},
	{ 
		value : 10,
		text : <span>10 minutes</span>
	  },
	  { 
		value : 15,
		text : <span>15 minutes</span>
	  },
	  { 
		value : 30,
		text : <span>30 minutes</span>
	  }
 ];

 export const shutdownOptions = () => [
	{ 
	  value : 'logout',
	  text : <span>Log out</span>
	},
	{ 
		value : 'shutdown',
		text : <span>Shut down</span>
	  },
	  { 
		value : 'lock',
		text : <span>Lock screen</span>
	  }	  

 ];

 export const statusOptions = () => [
	{ 
	  value : 'allow',
	  text : <div className="blacklist-status-container"><Icon name="check circle" style={{fontSize : "18px", color : "#28a745", marginRight: 8, float : "left"}}/>Allow</div>
	},
	{ 
		value : 'limit',
		text : <div className="blacklist-status-container"><Icon name="time" style={{fontSize : "18px", color : "#f5c242", marginRight: 8, float : "left"}}/>Limit</div>,
	  },
	  { 
		value : 'block',
		text : <div className="blacklist-status-container"><Icon name="minus circle" style={{fontSize : "18px", color : "#dc3545", marginRight: 8, float : "left"}}/>Block</div>
	  }	  
 ];

 export const webCategories = [
	{ 
		label : 'YouTube / Video',
		name : 'category3',
		image : require("../images/youtube.svg")				
	},

	{ 
		label : 'Games',
		name : 'category11',
		image : require("../images/gamepad.svg")		
	},	

	{ 	  
		label : 'Social Networks',
		name : 'category1',
		image : require("../images/facebook.svg")		
	},
		
	{ 
		label : 'Instagram',
		name : 'category2',
		image : require("../images/instagram.svg")				
	},

	{ 
		label : 'Messengers',
		name : 'category9',
		image : require("../images/messenger.svg"),			
	},	
	{
		label: 'News',
		name: 'category15',
		image: require("../images/news.svg")
	},			
	{ 
		label : ' Shopping',
		name : 'category8',
		image : require("../images/shop.svg")				
	},

	{ 
		label : 'Torrents',
		name : 'category12',
		image : require("../images/utorrent.svg")				
	},		

	{ 
		label : 'Dating',
		name : 'category10',
		image : require("../images/dating.svg")				
	},	



	{ 	  
		label : 'Adult / Porn',
		name : 'category4',
		image : require("../images/adult.svg")				
	},
	{ 	  
		label : 'Gambling',
		name : 'category7',
		image : require("../images/poker.svg")				
	},



		

 ];

 export const appCategories = [
	{ 	  
		label : 'Games',
		name : 'category4',
		image : require("../images/gamepad.svg")
	},
	{ 	  
		label : 'Browsers',
		name : 'category1',
		image : require("../images/globe.png")
	},
	{ 	  
		label : 'Torrents',
		name : 'category2',
		image : require("../images/utorrent.svg")
	},	
	{ 	  
		label : 'Messengers',
		name : 'category3',
		image : require("../images/messenger.svg")
	},		
 ];

// version 6.3.1 new categories
 export const appCategories_2022 = [
	{ 	  
		label : 'Games',
		name : 'category4',
		image : require("../images/gamepad.svg")
	},
	{ 	  
		label : 'Browsers',
		name : 'category1',
		image : require("../images/globe.png")
	},
	{ 	  
		label : 'Torrents',
		name : 'category2',
		image : require("../images/utorrent.svg")
	},	
	{ 	  
		label : 'Messengers',
		name : 'category3',
		image : require("../images/messenger.svg")
	},		
	{ 	  
		label : 'Audio Players',
		name : 'category5',
		image : require("../images/music.png")
	},	
	{ 	  
		label : 'Video Players',
		name : 'category6',
		image : require("../images/play.png")
	},	
	{ 	  
		label : 'Unsupported Browsers',
		name : 'category7',
		image : require("../images/globe.png")
	},				
 ];

 
 // version 8.4.1 new categories
 export const appCategories_2023 = [
	{ 	  
		label : 'Games',
		name : 'category4',
		image : require("../images/gamepad.svg")
	},
	{ 	  
		label : 'Browsers',
		name : 'category1',
		image : require("../images/globe.png")
	},
	{ 	  
		label : 'Torrents',
		name : 'category2',
		image : require("../images/utorrent.svg")
	},	
	{ 	  
		label : 'Messengers',
		name : 'category3',
		image : require("../images/messenger.svg")
	},		
	{ 	  
		label : 'Audio Players',
		name : 'category5',
		image : require("../images/music.png")
	},	
	{ 	  
		label : 'Video Players',
		name : 'category6',
		image : require("../images/play.png")
	},	
	{ 	  
		label : 'Unsupported Browsers',
		name : 'category7',
		image : require("../images/globe.png")
	},		
	{ 	  
		label : 'Process Managers',
		name : 'category8',
		image : require("../images/tasks.png")
	},				
	{ 	  
		label : 'Installers',
		name : 'category9',
		image : require("../images/installers.png")
	},				

 ];

