import React, { useState } from 'react'
//import {Button} from 'semantic-ui-react';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const Confirmation = props => {
    return (     
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {props.text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <Button onClick={props.handleOK}>OK</Button>
            </DialogActions>
          </Dialog>
      )
  }

  export const ConfirmationAll = props => {

    const names = props.computers.map((item) => item.name);
    names.splice(names.indexOf(props.from), 1);
    const [checked, setChecked] = useState(names);

    const handleCheck = (event) => {
      var updatedList = [...checked];
      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);    
    };

    const list = names.map((item) => (
      <div key={item} style={{margin : 5}}>
        <input value={item} type="checkbox" checked = {checked.includes(item)} onChange={handleCheck} />
        <span style={{marginLeft : '10px'}}>{item}</span>
      </div>
      ))

    return (     
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {props.text}
                <div style={{marginLeft : "10px", marginTop : "20px"}}>{list}</div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <Button onClick={() => props.handleOK(checked)}>OK</Button>
            </DialogActions>
          </Dialog>
      )
  }

  ;