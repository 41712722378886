import React, { Component } from 'react';
import firebase from '../../firebase';
import {Dropdown, Icon} from 'semantic-ui-react';
import Schedule from '../UI/Schedule';
import Slider from '../UI/Slider';
import {minutesToString} from '../../utils';
import {modeOptions} from '../consts';
import withUID from './../withUID';
import ApplicationList from './../UI/ApplicationList';
import moment from 'moment';
import ApplyButton from './../UI/ApplyButton';
import WebsiteListWithSearch from './../UI/WebsiteListWithSearch';
import { applySettings } from './../../Firebase/applySettings';
 
class TimeManagement extends Component {
    state = {
        settingsRef : null,   
        applySettingsRef : null,           
        settings : {},          
        data : null, // updated settings
    }

    getInstalledApplications = (uid, currentComputer) => { 
        // check cache
        const timestamp = +localStorage.getItem(`timestamp/${uid}/${currentComputer}/applications`);    
        if (moment().unix() < timestamp + 60) {
            const installedApplications = JSON.parse(localStorage.getItem(`${uid}/${currentComputer}/applications`));    
            this.setState({installedApplications});               
        } else {
            const applicationsRef = firebase.database().ref(`${uid}/${currentComputer}/applications`);
            applicationsRef.once('value',
            // success
            (snapshot) => {
                const raw = snapshot.val() || {};
                const installedApplications = Object.keys(raw).filter(key => raw[key]).map((key) => {
                    return(
                        {key, name: raw[key]}
                    )
                });
            
                installedApplications.sort((a, b) => a.name.localeCompare(b.name));

                localStorage.setItem(`${uid}/${currentComputer}/applications`, JSON.stringify(installedApplications));
                localStorage.setItem(`timestamp/${uid}/${currentComputer}/applications`, moment().unix());    
                this.setState({installedApplications});                   
              },
            // error
            (error) => {
                console.log(error);            
            });    
        }
    }       
    
    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);                 
        this.setState({settingsRef});
        settingsRef.child('timeManagement').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }

    componentDidMount() {
        if (this.props.currentComputer) this.getInstalledApplications(this.props.uid, this.props.currentComputer);            
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }    
    
    componentWillUnmount() {
        if (this.state.data) {        
            this.handleSaveSettings();
        }
    }    

    handleSaveSettings = () => {            
        this.state.settingsRef.child("timeManagement").update(this.state.data);        
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		
    }

    onChange = (name, value) => {             
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value}
        }));        
    }

    render() {			
        const maxLimit = 121; // 120 - 10 hours, 121 - Unlimited
        const {applications = [], websites = [], timeLimit = 121, mode = 'day', schedule} = this.state.settings;    
        const minutes = timeLimit * modeOptions.find(item => item.value === mode).multiplier;                         
        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/>            
				<h2>Time Management
                    <a href='https://ht-vector.com/ht-task-vector-how-to-use#time-management' rel="noopener noreferrer" target="_blank"><Icon name='question circle' className="help-icon"/></a>    
                </h2>	                                                                
                <div className="flex-box" style={{marginTop: "-15px"}}>
                <div style={{width : '550px', marginTop : "25px", marginRight: "10px"}}>                        
                        <h3>Unproductive websites</h3>                                               
                        <WebsiteListWithSearch list={websites} name='websites' onChange={this.onChange} style = {{padding : 10, marginRight : '15px',  height : '350px', overflowY: 'auto', border : '1px solid #ddd'}}/>                                                             
                    </div>                      
                    <div style={{width : '550px', marginTop : "25px", marginRight: "10px"}}> 
                        <h3>Unproductive applications</h3>                           
                        <ApplicationList installedApplications={this.state.installedApplications} list={applications} name='applications' onChange={this.onChange} style = {{padding : 10, marginRight : '15px',  height : '350px', overflowY: 'auto', border : '1px solid #ddd'}}/>                                                             
                    </div>                                 
                    <div style={{minWidth : "300px", width : '550px'}}>
                        <h3>Set time restrictions on the limited websites &amp; apps</h3>                    
                            <Slider value={timeLimit} min={1} max={maxLimit} onChange={(e, value) => this.onChange('timeLimit', value)}/>
                            <div style={{padding : "10px", paddingTop: "0"}}>
                                {timeLimit < maxLimit ? minutesToString(minutes) : "Unlimited"}
                                {(timeLimit < maxLimit)&&<Dropdown                             
                                    style={{margin : 0, marginLeft: "10px"}} 
                                    value = {mode}
                                    onChange = {(e, {value}) => this.onChange('mode', value)}
                                    options={modeOptions}
                                />}                     
                            </div>                
                        <Schedule schedule={schedule} onChange={schedule => this.onChange('schedule', schedule)}/>                              
                    </div>                
                </div>                

            </>
        )
    }
}

export default withUID(TimeManagement);
 