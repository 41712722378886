import React, { Component } from 'react'
import {Grid, Form, Segment, Button, Message, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import firebase from '../../firebase';
import { isValidEmail, isFormEmpty, isPasswordsMatch, isPasswordTooShort } from './validation';
import Logo from '../UI/Logo';
import {sendMail} from '../../status';
import { privacyPolicyLink, termsLink } from '../consts';
import ToDoList from './TodoList';


export default class Register extends Component {

    state = {
        username : '',
        email : '',
        password : '',
        passwordConfirm : '',
        errors : [],
        loading : false, 
        showPassword: false, 
        showConfirmPassword : false,
    };

    componentDidMount() {
        const savedEmail = localStorage.getItem('email');
        if (savedEmail) {
            this.setState({ email: savedEmail });
        }
    }    

    displayErrors = errors => errors.map((error, i) => 
        <p key = {i}>{error.message}</p>)
    
    isFormValid = () => {
        const {username, email, password, passwordConfirm} = this.state;
        let errors = [];
        let error;    
        if (isFormEmpty(username, email, password, passwordConfirm)) {
            error = { message : 'Fill in all fields'};
            this.setState({errors: errors.concat(error)})
            return false;
        } else if (!isValidEmail(email)) {
            error = { message : 'Email is invalid'};
            this.setState({errors: errors.concat(error)})
            return false;
        }        
        else if (isPasswordTooShort(password)) {
            error = { message : 'Password is too short. The password must be at least 6 characters'};
            this.setState({errors: errors.concat(error)})
            return false;
        }
        else if (!isPasswordsMatch(password, passwordConfirm)) {
            error = { message : 'Passwords do not match. Please re-enter your password.'};
            this.setState({errors: errors.concat(error)})
            return false;
        } else return true;
    }

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
          showPassword: !prevState.showPassword,
        }))
      }
      
      toggleConfirmPasswordVisibility = () => {
        this.setState((prevState) => ({
          showConfirmPassword: !prevState.showConfirmPassword,
        }))
      }      

    handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            localStorage.setItem('email', value);
        }
        this.setState({ [name]: value });
    }

    handleSubmit = async (event) => {    
        event.preventDefault();
        if (this.isFormValid()) {
            try {
                this.setState({errors : [], loading : true})
                const createdUser = await firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password);            
                await createdUser.user.updateProfile({displayName : this.state.username});
                //await createdUser.user.sendEmailVerification();                
                await sendMail(this.state.username, this.state.email);
                this.setState({loading : false})
            } catch(error) {
                console.log(error);
                this.setState({errors : this.state.errors.concat(error), loading : false});                
            }
        }
    }
    
    render() {
        const { email, password, username, passwordConfirm, showPassword, showConfirmPassword, errors, loading} = this.state;
        return (
            <div className="login-container">
            <Grid textAlign="center" verticalAlign="middle" className="app">
                <Grid.Column style={{maxWidth : 450}}>
                    <Form size="large" onSubmit={this.handleSubmit}>
                        <Segment basic style={{padding : '20px', backgroundColor : "#fff"}}>
                        <Logo/>
                            <h2 className="login-title">Get Started for Free</h2>
                            <Form.Input fluid name="username" icon="user" iconPosition="left" 
                                placeholder="Username (optional)" onChange={this.handleChange} type="text" value={username}/>                            
                            <Form.Input fluid name="email" icon="mail" iconPosition="left" 
                                placeholder="Email Address" onChange={this.handleChange} type="email" value={email}/>
                            <Form.Input fluid name="password" icon="lock" iconPosition="left" 
                                placeholder="Password" onChange={this.handleChange} type={showPassword ? 'text' : 'password'}  value={password}/>
                            <Icon
                                name={showPassword ? 'eye slash outline' : 'eye'}
                                link
                                onClick={this.togglePasswordVisibility}
                                style={{position: 'absolute', color: '#666', right: '2em', marginTop: '-3em', }}
                            />                                
                            <Form.Input fluid name="passwordConfirm" icon="repeat" iconPosition="left" 
                                placeholder="Password Confirmation" onChange={this.handleChange} type={showConfirmPassword ? 'text' : 'password'} value={passwordConfirm}/>
                            <Icon
                                name={showConfirmPassword ? 'eye slash outline' : 'eye'}
                                link
                                onClick={this.toggleConfirmPasswordVisibility}
                                style={{position: 'absolute', color: '#666', right: '2em', marginTop: '-3em', }}
                            />                                  
                            <div style={{display: "flex", justifyContent : "center"}}>
                                <Button disabled={loading} className={loading ? 'loading' : ''} color="blue" fluid size="large" style={{width: "95%", boxShadow: "0 5px 5px rgba(0,0,0,0.25)"}}>SIGN UP</Button>                                                                
                            </div>
                        <div style={{marginTop : "30px"}}>                        
                            Already have an account? <Link style={{marginLeft: '5px'}} to="/login">Log in here.</Link>
                        </div>     
                        <div style={{color: '#888', fontSize : '0.9rem', marginTop : "40px", paddingTop: "20px", borderTop : "1px solid #eee"}}>                       
                            By signing up, you agree to our <a target="_blank" rel="noopener noreferrer" href={termsLink}>terms</a> and <a target="_blank" rel="noopener noreferrer" href={privacyPolicyLink}>privacy policy</a>.
                        </div>                            
                        </Segment>  
                    </Form>
                    {errors.length > 0 && 
                        <Message error>
                            <h3>Error</h3>
                            {this.displayErrors(errors)}
                        </Message>
                    }
                </Grid.Column>        
            </Grid>
            <ToDoList />            
            </div>            
        )
    }
}
