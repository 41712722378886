import React, { Component} from 'react';
import {Button, List} from 'semantic-ui-react';

class KeyList extends Component {
    state = {        
        newItem : '',
    }

    handleAddItem = () => {        
        if (this.state.newItem !== '') {
            const list = this.props.list || [];
            const r = list.find((item) => item === this.state.newItem);
            if (!r) {
                list.push(this.state.newItem);                
                this.setState({newItem : ''});
                this.props.onChange(this.props.name, list);
            }
        }
    }

    handleRemoveItem = (removedItem) => {                
        const list = [...this.props.list];        
        const index = list.indexOf(removedItem)
        if (index !== -1) {
            list.splice(index, 1);            
            this.props.onChange(this.props.name, list);
        }
    }    

    handleKeyDown = (event) => {
        if (event.key === 'Enter') this.handleAddItem();
    }

    render() {			
        const {list = []} = this.props;    
        const listItems = list.map((item) => (            
            <List.Item key={item}>
                <List.Content floated='right'>
                    <Button size='mini' circular icon='delete' onClick={() => this.handleRemoveItem(item)}/>
                </List.Content>               
                <List.Content verticalAlign='middle' className="trunc" title={item}>                 
                    {item}
                </List.Content>
            </List.Item>                            
        ));        
        return (                        
            <>
                <div style={this.props.style} >
                    <List verticalAlign='middle' selection size="large">{listItems}</List>                               
                </div>                        
                <div style={{marginTop : "10px", marginRight: '15px', display : 'flex', flexDirection : 'row', alignItems: 'space-between'}}>
                    <input placeholder="Add URLs or keywords" style={{paddingLeft: '10px', border : "1px solid #ccc", flexGrow: '1', marginRight : '10px'}} onKeyDown={this.handleKeyDown} onChange={(event)=> {this.setState({newItem : event.target.value})}} value={this.state.newItem}/>
                    <Button onClick={this.handleAddItem}>Add</Button>
                </div>	                                                                                              
            </>                
        )
    }
}   

export default KeyList;