import React from 'react';
import Computers from './Computers';
import ComputerMenu from './SidebarNavigation';

export const Sidebar = () => {      
    return (   
      <nav className="main-sidebar">
        <div className="sidebar-sticky">
          <div className="nav flex-column " style={{display: "block"}}>      
            <Computers/>
            <ComputerMenu/>                  
          </div>
        </div>
      </nav>  
    )
  }
  
  export const CollapsedSidebar = () => {  
  return (   
  <nav className="main-sidebar">  
      <div className="nav flex-column " style={{marginTop: "45px", display: "block"}}>      
        <Computers size="mini"/>
        <ComputerMenu collapsed/>
      </div>  
  </nav>  
  )
  }
