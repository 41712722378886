import React, {useState} from 'react';
import {lastSeen, } from '../../utils';
import moment from 'moment';
import ComputerMenu from './ComputerMenu';
import BadgeAvatar from '../UI/BadgeAvatar';

const Computer = ({alias, status, name, version, online, index}) => {  
    const [lastTimestamp, setLastTimestamp] = useState(0); 
    const [lastOnline, setLastOnline] = useState(0);    
       
    if (online !== lastOnline) {
        if (lastOnline > 0) setLastTimestamp(moment().unix());
        setLastOnline(online);
    }

    const realOnline = lastTimestamp > 0 ? lastTimestamp : online;

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <BadgeAvatar status={status === '0' ? 'active' : 'paused'} online={lastSeen(realOnline)} name={!alias ? name : alias} index={index} />
            <div style={{position:'relative', lineHeight: '1.2em', marginLeft: 10, display: 'inline-block', width: '70%', overflow : 'hidden', textOverflow: 'ellipsis'}}>{!alias ? name : alias}</div>        
            <ComputerMenu name={name} realOnline={realOnline} alias={alias} version={version}/>      
        </div>
        );    
}

export default Computer;
