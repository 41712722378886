import firebase from './../firebase';

export function applySettings(uid, currentComputer) {  
    if (!currentComputer) return;
    const applySettingsRef = firebase.database().ref(`${uid}/${currentComputer}/systemInfo`);     
    const timestamp = firebase.database.ServerValue.TIMESTAMP
    applySettingsRef.update({timestamp}); 
    // new 16.05.2020 optimized
    firebase.database()
        .ref(`${uid}/${currentComputer}/sysInfo`)
        .update({a : firebase.database.ServerValue.increment(1)})
}

export function takeScreenshot(uid, currentComputer) {  
    firebase.database()
        .ref(`${uid}/${currentComputer}/commands`)
        .update({takeScreen : firebase.database.ServerValue.TIMESTAMP})
}

export function changeStatus(uid, currentComputer, status) {
    const changeStatusRef = firebase.database().ref(`${uid}/${currentComputer}/systemInfo`);     
    const statusTimestamp = firebase.database.ServerValue.TIMESTAMP
    changeStatusRef.update({status, statusTimestamp, bypassed: '0'}); 

    // new 16.05.2020 optimized
    const s = status === 'inactive' ? 0 : 1;
    firebase.database()        
        .ref(`${uid}/${currentComputer}/sysInfo`)
        .update({s, c : firebase.database.ServerValue.increment(1)})
}