import React from 'react';
import Applications from './Reports/Applications';
import Websites from './Reports/Websites';
import ComputerTime from './Reports/ComputerTime';
import ComputerTime_v2 from './Reports/ComputerTime_v2';
import WindowsSecurity from './Settings/WindowsSecurity';
import GeneralSettings from './Settings/GeneralSettings';
import WebsiteBlocking from './Settings/WebsiteBlocking';
import TimeManagement from './Settings/TimeManagement';
import WebsiteFilter from './Settings/WebsiteFilter';
import ApplicationFilter from './Settings/ApplicationFilter';
import UserSettings from './Settings/UserSettings';
import Alerts from './Reports/Alerts';
import {Switch, Route} from 'react-router-dom';
import Queries from './Reports/Queries';
import UserSettings_old from './Settings/UserSettings_old';
import WebsiteFilter_old from './Settings/WebsiteFilter_old';
import ApplicationFilter_old from './Settings/ApplicationFilter_old';
import Dashboard from './Dashboard/Dashboard';
import TrackingSettings from './Settings/TrackingSettings';
import withUID from './withUID';


const Content = ({version}) => {
    return (
      <div className="main-content ml-auto static content-scroll">
        <Switch>             
          <Route path="/" exact component={Dashboard} />                
          <Route path="/applications" component={Applications} />
          <Route path="/websites" component={Websites} />                             
          <Route path="/computer_time" component = {version >= 6201 ? ComputerTime_v2 : ComputerTime} />      
          <Route path="/search_queries" component={Queries} />                  
          <Route path="/alerts" component={Alerts} />     
          <Route path="/time_management" component={TimeManagement} />                      
          <Route path="/website_blocking" component={WebsiteBlocking} />               
          <Route path="/website_filter" component={version < 6101 ? WebsiteFilter_old : WebsiteFilter} />  
          <Route path="/windows_security" component={WindowsSecurity} />            
          <Route path="/application_filter" component={version < 6101 ? ApplicationFilter_old : ApplicationFilter} />
          <Route path="/tracking_settings" component={TrackingSettings} /> 
          <Route path="/general_settings" component={GeneralSettings} /> 
          <Route path="/user_settings" component={version < 6101 ? UserSettings_old : UserSettings} />                    
        </Switch>    
      </div>
  )
  };
export default withUID(Content);