import React, { useState, useEffect } from 'react';
import md5 from 'js-md5';
import firebase from '../../firebase';
import withUID from '../withUID';
import { Icon } from 'semantic-ui-react';

const robloxIcon = 'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAABCFBMVEX7///9//////3+/v/////7/fz3///y+PvZ3eP5/f/3+//v8/Ts7vPi5eqYpa+bpaz2///8/f/4/P34+/rt8PbV19zMz9bJzdHFydG/yNGxusOttLuXo6yVoaqLmKP2+vv2+Pns8PTi6fTq7O/l6O3e5Ojc4ObW2uDT197N1N3R1NrDztnJ0dfBy9S7wsu6vsa4vsSotcGls8Cut72jrrmlr7SorrSep66RnqeTnqTx+v7t9v3x9fjh6/f09vXZ5fPn6+/Y4uvR3enN2efQ1+HW3uDJzdS8yNTEzM/Bxc7Cx8qzvcezt8Ctt8Cnsbqqs7ifq7eaqLObpa6aoaedoqWKlJ2NlpuEkZnenLphAAAA4ElEQVQY0y3PVXbEMAxAUTl27EzDzBmedpipzMy4/51UOe3Vh47en0CS5BKllBCKAEB2KgA7CBcpg1t1Ko449Oqa5nllqKlqvXna0jvK7p5PgdhqS28rCl69IAgpMKb7ne6g0b2M7qJxjwGr+dF13Of7jXg0ftQJMC0cxcNzyzp6SFapIGArk+RpenNx0l8szAQIiCCdP79OZ7M3I8+HHECbfJhZZhhFkRXGmQWg3qZLc/uTbzbvX8sDDoiL9uBla67X33OO4Z+4ul99hvgNfktK4NrHTZdUMfxhOLLECPwCDykYIDuoFgEAAAAASUVORK5CYII=';

function AppIcon({ name, uid, currentComputer }) {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    if (name === 'Roblox') {
      setIcon(robloxIcon);
      return;
    }

    const md5Name = md5(name);
    const storedIcon = localStorage.getItem(md5Name);
    const iconRef = firebase.database().ref(`${uid}/${currentComputer}/icons/${md5Name}`);    

    if (storedIcon) {
      setIcon(storedIcon);
    } else {     
      iconRef.once('value',  // success
        (snapshot) => {
          const newIcon = snapshot.val() || null;
          localStorage.setItem(md5Name, newIcon);
          setIcon(newIcon);
        }, // error
        (error) => {
          console.log(error);
        }
      );
    }
  }, [uid, currentComputer, name]);

  const style = {
    backgroundImage: `url("data:image/png;base64,${icon}")`
  };

  return icon !== 'null' ? (
    <div className="app-icon trunc" style={style}>
      {name}
    </div>
  ) : (
    <div>
      <Icon
        name="window maximize outline"
        color="grey"
        style={{ marginLeft: 5, marginRight: 10 }}
      />
      {name}
    </div>
  );
}

export default withUID(AppIcon);