import React, { Component } from 'react';
import firebase from '../../firebase';
import {Dropdown, List, Image} from 'semantic-ui-react';
import Schedule from '../UI/Schedule';
import Slider from '../UI/Slider';
import {minutesToString} from '../../utils';
import {modeOptions2, statusOptions} from '../consts';
import withUID from './../withUID';
import ApplyButton from './../UI/ApplyButton';
import { applySettings } from './../../Firebase/applySettings';
import WebsiteListWithSearch from './../UI/WebsiteListWithSearch';

const webCategories = [
	{ 	  
		label : 'Social Networks',
		name : 'webCategory1',
		image : require("../../images/facebook.svg")		
	},
	{ 
		label : 'Games',
		name : 'webCategory11',
		image : require("../../images/gamepad.svg")		
	},			
	{ 
		label : 'Instagram',
		name : 'webCategory2',
		image : require("../../images/instagram.svg")				
	},
	{ 
		label : 'YouTube / Video',
		name : 'webCategory3',
		image : require("../../images/youtube.svg")				
	},
	{ 	  
		label : 'Adult / Porn',
		name : 'webCategory4',
		image : require("../../images/adult.svg")				
	},
	{ 	  
		label : 'Gambling',
		name : 'webCategory7',
		image : require("../../images/poker.svg")				
	},
	{ 
		label : ' Shopping',
		name : 'webCategory8',
		image : require("../../images/shop.svg")				
	},
	{ 
		label : 'Chat sites',
		name : 'webCategory9',
		image : require("../../images/messenger.svg")				
	},	
	{ 
		label : 'Dating',
		name : 'webCategory10',
		image : require("../../images/adult.svg")				
	},	
	{ 
		label : 'Torrents',
		name : 'webCategory12',
		image : require("../../images/utorrent.svg")				
	},		

 ];

class WebsiteFilter extends Component {
    state = {
        settingsRef : null,                
        settings : {},          
        data : null, // updated settings
    }
    
    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);                      
        this.setState({settingsRef});
        settingsRef.child('timeManagement').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }

    componentDidMount() {
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }     

    componentWillUnmount() {
        if (this.state.data) {
            this.handleSaveSettings();
        }
    }    

    handleSaveSettings = () => {                
        this.state.settingsRef.child("timeManagement").update(this.state.data);        
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		
    }

    onChange = (name, value) => {         
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value}
        }));        
    }

    render() {			
        const maxLimit = 121; // 120 - 10 hours, 121 - Unlimited
        const {websites = [], timeLimit = 121, mode = 'day', schedule} = this.state.settings;    
        const minutes = timeLimit * modeOptions2.find(item => item.value === mode).multiplier;            
       //Categories
       const webCategoriesList = webCategories.map((item) => (            
        <List.Item key={item.name}>
            <div className='blacklist-container' style={{padding: "0.3rem"}}>
                <div className='blacklist-title'>
                    <Image floated='left' style={{opacity: '0.6', width: '1.3em', height: '1.3em'}} src={item.image}/>
                    <div style={{color : 'rgba(0,0,0,.87)'}}>{item.label}</div>                    
                </div>  
                <div className='blacklist-status' style={{marginRight: '55px'}}>
                    <Dropdown                                                     
                        value = {this.state.settings[item.name]}
                        size="large"
                        onChange = {(e, {value}) => this.onChange(item.name, value)}
                        options={statusOptions()}
                        defaultValue='allow'
                    />
                </div>                                                                                      
            </div>              
        </List.Item>                            
    ));         

        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/>               
				<h2>Website Limits</h2>	                                                                              
                <div className="flex-box" style={{marginTop: "-15px"}}>
                        <div style={{width : '550px', marginTop : "0px", marginRight: "10px"}}>                                            
                            <h3>Website Categories</h3>                    
                            <div style={{marginTop : "0px", marginRight: "10px"}}>                          
                                <List selection size="large">{webCategoriesList}</List>  
                            </div>                           
                            </div> 
                        <div style={{width : '550px', marginTop : "0px", marginRight: "10px"}}>                                                                        
                            <h3>Custom List</h3>                        
                            <WebsiteListWithSearch list={websites} name='websites' onChange={this.onChange} style = {{padding : 10, marginRight : '15px',  height : '320px', overflowY: 'auto', border : '1px solid #ddd'}}/>                                                             
                        </div>  
                 </div>
                 <div className="flex-box">                               
                    <div style={{width : "550px", paddingRight: '25px'}}>
                        <h3>Set time restrictions on the limited apps & websites</h3>                                            
                            <Slider value={timeLimit} min={1} max={maxLimit} onChange={(e, value) => this.onChange('timeLimit', value)}/>
                            <div style={{padding : "10px", paddingTop: "0"}}>
                                {timeLimit < maxLimit ? minutesToString(minutes) : "Unlimited"}
                                {(timeLimit < maxLimit)&&<Dropdown                             
                                    style={{margin : 0, marginLeft: "10px"}} 
                                    value = {mode}
                                    onChange = {(e, {value}) => this.onChange('mode', value)}
                                    options={modeOptions2}
                                />}                     
                            </div>        
                    </div>
                    <div style={{minWidth : "300px"}}>                        
                        <Schedule schedule={schedule} onChange={schedule => this.onChange('schedule', schedule)}/>                                                                                      
                    </div>                                                    
                </div>            
            </>
        )
    }
}

export default withUID(WebsiteFilter);
 