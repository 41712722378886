import React, {useState} from 'react';
import {Confirmation} from './Confirmation';
import {Button} from 'semantic-ui-react';

const ClearLogsButton = (props) => {
    const [showConfirm, setShowConfirm] = useState(false);
    
    const handleOK = () => {
        setShowConfirm(false)
        props.onClick();
    }
    return (
    <>
        <Confirmation 
            title="Clear log?"
            text="This will remove all history and cannot be undone." 
            open={showConfirm}
            handleClose={() => setShowConfirm(false)}
            handleOK={handleOK}
        />
        <div className="sticky-button">
            <Button onClick={() => setShowConfirm( true)}>Clear logs</Button>                
        </div> 
    </>
    )
}

export default ClearLogsButton;