import axios from 'axios';

const API_KEY = '9170081a-1b4f-4d9c-94bf-14c291006ac5';
const API_URL = 'https://manage.ht-vector.com/status.php?auth=';
const API_SEND_URL = 'https://manage.ht-vector.com/start.php?auth=';

export async function sendMail(name, email) {
    try {
        const url = `${API_SEND_URL}${API_KEY}&name=${name}&mail=${email}`;
        const response = await axios.get(url);        
        return response;
      } catch (error) {
        console.error(error);
      }
    }

  export async function getStatus(email) {
      try {
          const url = `${API_URL}${API_KEY}&email=${email}`;
          const response = await axios.get(url);        
          return response.data;
        } catch (error) {
          console.error(error);
        }
      }