import React, { useState } from 'react';
import { weekdays, hours } from '../consts';
import './schedule.css';
import ToolTip from './ToolTip';

const Cell = (props) => {
	const { value, x, y } = props;
	const className = `cell ${value === '1' ? 'cell-inactive' : 'cell-allow'}`;
	return (
		<div
			onMouseUp={props.onMouseUp}
			onMouseMove={() => props.onMouseMove(x, y)}
			onMouseDown={() => props.onMouseDown(x, y)}
			className={className}
		></div>
	);
};

const TrackingSchedule = (props) => {
	const defaultSchedule = [
		'000000000000000000000000',
		'000000000000000000000000',
		'000000000000000000000000',
		'000000000000000000000000',
		'000000000000000000000000',
		'000000000000000000000000',
		'000000000000000000000000',
	];
	const [fill, setFill] = useState(null);

	const onMouseDown = (x, y) => {
		let newFill = null;
		const schedule = (props.schedule || defaultSchedule).map((row, _y) => {
			const cells = row.split('').map((cell, _x) => {
				if (_x === x - 1 && _y === y - 1) {
					newFill = cell === '1' ? '0' : '1';
					return newFill;
				} else {
					return cell;
				}
			});
			return cells.join('');
		});

		props.onChange(schedule);
		setFill(newFill);
	};

	const onMouseMove = (x, y) => {
		if (!fill) return;
		const schedule = (props.schedule || defaultSchedule).map((row, _y) => {
			const cells = row.split('').map((cell, _x) => {
				if (_x === x - 1 && _y === y - 1) {
					return fill;
				} else {
					return cell;
				}
			});
			return cells.join('');
		});
		props.onChange(schedule);
	};

	const onMouseUp = () => {
		setFill(null);
	};

	const schedule = (props.schedule || defaultSchedule).map((row) => row.split(''));
	const midnigth = <div className='half-day'>Midnight (AM)</div>;
	const noon = <div className='half-day'>Noon (PM)</div>;

	const hoursRow = hours.map((hour) => (
		<div key={hour.value} className='hour-item'>
			{hour.label}
		</div>
	));
	let y = 0;
	const table = schedule.map((row) => {
		let weekday = <div className='week-title'>{weekdays[y]}</div>;
		y++;
		let x = 0;
		const cells = row.map((cell) => {
			x++;
			return (
				<Cell
					key={x}
					x={x}
					y={y}
					value={cell}
					onMouseUp={onMouseUp}
					onMouseMove={onMouseMove}
					onMouseDown={onMouseDown}
				/>
			);
		});

		return (
			<div key={y} className='item-root'>
				{weekday} {cells}
			</div>
		);
	});

	return (
		<div className='noselect'>
			<div className='block'>
				<h3>Tracking Schedule
                    <ToolTip 
                        text="The green hour block indicates the computer is being tracked. The grey hour block indicates that the computer is not being tracked."
                    />
                </h3>
			</div>
			<div className='halves-day'>
				{midnigth}
				{noon}
			</div>
			<div className='hours-header'>{hoursRow}</div>
			<div className='schedule'>{table}</div>
		</div>
	);
};

export default TrackingSchedule;
