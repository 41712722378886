import React, {useState, useEffect } from 'react';
import firebase from '../../firebase';
import {Icon, Label, Message} from 'semantic-ui-react';
import moment from 'moment';
import withUID from './../withUID';
import ClearLogsButton from '../UI/ClearLogsButton';
import AppChartTable from './tables/AppChartTable';
import withAppFilter from './withAppFilter';
import _ from 'lodash';
import DatePicker_2023 from '../UI/DatePicker_2023';

const Applications = (props) => {
    const [items, setItems] = useState([]);
    const [dates, setDates] = useState([]);
    const [isToday, setIsToday] = useState(false);
    const [lastUpdate, setLastUpdate] = useState(moment());  
    const [date, setDate] = useState(moment());
    const [week, setWeek] = useState(false);
    
    const { currentComputer, uid} = props;

    const startDate = moment(date).startOf('isoWeek');
    const days = !week ? [moment(date).format('YYMMDD')] : Array.from({ length: 7 }, (_, i) => (moment(startDate).add(i, 'days').format('YYMMDD'))); 

    const handleClearLogs = () => {
        const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/applications`);
        ref.remove();
        const commandsRef = firebase.database().ref(`${uid}/${currentComputer}/commands`);  
        const command = {clearApplications : firebase.database.ServerValue.TIMESTAMP}    
        commandsRef.update(command);    
        setDates([]);
        setItems([]); 
    }

    // get dates
    useEffect(() => {
        const datesRef = firebase.database().ref(`${uid}/${currentComputer}/reports/applications/dates`);
        datesRef.once('value',
            (snapshot) => {
                const datesRaw = Object.keys(snapshot.val() || {});
                if (datesRaw.length) {
                    const dates = datesRaw.map(date => moment(date, 'YYMMDD').toDate());                                   
                    const latestDate = moment(new Date(Math.max.apply(null, dates)));                     
                    const isToday = moment().diff(latestDate, 'days') === 0;                                        
                    setDates(dates);
                    if (moment().diff(date, 'days') === 0) setDate(latestDate);
                    setIsToday(isToday);
                }
            }, // error
            (error) => {
                console.log(error);            
            });                
    }, [uid, currentComputer]);

    useEffect(() => {
        setItems([]);
        if (currentComputer && dates) {
            const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/applications`);  
            days.forEach(date => {                 
                const dateExists = dates.some(d => moment(d).format('YYMMDD') === date);                
                if (dateExists) {                       
                    ref.child(date).once('value',                
                    // success
                    (snapshot) => {                
                        const items = Object.values(snapshot.val() || {});    
                        setItems(prevItems => ({ ...prevItems, [date]: items }));                                                                          
                    },
                    // error
                    (error) => {
                        console.log(error);            
                    });  
                }
            });                   
             
        }
    }, [uid, currentComputer, date, week, lastUpdate]);

	const handleChangeDate = (date) => {                  
        setDate(date);
    }   
        
    const handlePreviousDay = () => {
        const previousDay = moment(date).subtract(week ? 7 : 1, 'day');
        setDate(previousDay);
      };
    
    const handleNextDay = () => {
        const nextDay = moment(date).add(week ? 7 : 1, 'day');
        setDate(nextDay);
      };    
      
    const groupedData = _.groupBy(_.flatten(Object.values(items)), 'name');
    const itemsWeek = _.map(groupedData, (items, name) => ({
        name,
        time: _.sumBy(items, item => Number(item.time))
    }));
    itemsWeek.sort((a,b) => b.time - a.time);   

    const chart = itemsWeek || [];    
    const totalTime = chart.reduce((total, item) => (total + parseInt(item.time)), 0);  
    const weekStart = moment(date, 'YYMMDD').startOf('isoWeek');
    const weekEnd = moment(date, 'YYMMDD').endOf('isoWeek');  
    const formatedDate = !week ? moment(date, 'YYMMDD').format('MMM DD, YYYY') : `${weekStart.format('MMM DD, YYYY')} - ${weekEnd.format('MMM DD, YYYY')}`;
    const formatedLastUpdate = moment(lastUpdate).format('LTS');         

    return (    
        <>
            <ClearLogsButton onClick={handleClearLogs}/>
            <h2>Applications</h2>	
            <div style={{padding: 10, minWidth : 450}}>	
                {!isToday&&<Message style={{marginBottom:30}} warning header="Today" content="No activity today"/>}		
                <h2 style={{marginBottom : 0, marginLeft: 20}}>
                    <span className='date-box' style={{width: '100%', textAlign : 'right'}}>                
                        <span className={`tab-button day ${!week ? 'active' : ''}`} onClick={() => setWeek(false)}>DAY</span>            
                        <span className={`tab-button week ${week ? 'active' : ''}`} onClick={() => setWeek(true)}>WEEK</span>                                                                                                
                        <Icon className='date-change' style={{marginLeft: 0}} name='angle left' onClick={handlePreviousDay} />                   
                        <Icon className='date-change' name='angle right' onClick={handleNextDay} />        
                        <DatePicker_2023 date={moment(date)} includes={dates} onChangeDate={handleChangeDate}/>   
                        <span style={{marginLeft : 5, fontSize: '0.9em'}}>{formatedDate}</span>              
                        <Label title="refresh" as='a' style={{marginLeft : 20, top : -5}} onClick={() => setLastUpdate(moment())}>
                            <Icon name='refresh' className='date-change' />  
                            {formatedLastUpdate}
                        </Label>              
                    </span>        
                </h2>	                                 
                <AppChartTable chart={chart} totalTime={totalTime}/>
            </div>                        
        </>
    )
}

export default withUID(withAppFilter(Applications));
