import React, { useState, useEffect, PureComponent } from 'react';
import firebase from '../../firebase';
import moment from 'moment';
import withUID from './../withUID';
import { secondsToString } from '../../utils';
import { Message } from 'semantic-ui-react';
import { appToCategory } from './appCategories';
import { webToCategory } from './webCategories';
import _ from 'lodash';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';

  const colorsMapping = {
    "Other" : '#64b5f6',         
    "Education" : '#4db6ac', 
    "Games" : '#ff8a65', 
    "Communication" : '#ffb74d', 
    "Social Media" : '#9575cd',
    "Entertainment" : '#f06292',
    "Creative" : '#4d97b5', 
    "Video" : '#ce3a2f',
    "Productivity" : '#88b24c',
    "News" : '#4d6677', 
    "Unsupported Browsers" : '#ad704a',
    "Development" : '#4d81b5',
}   

/*
const data = [
  {name : "Other", value : 100}, 
  {name: "Entertainment", value: 100},
  {name: "Social Media", value: 100},
  {name: "Communication", value: 100},
  {name: "Games", value: 100},
  {name: "Education", value: 100},
  {name: "Unsupported Browsers", value: 100},
  {name: "Video", value: 100},
  {name: "Productivity", value: 100},
  {name: "News", value: 100},
  {name: "Creative", value: 100},
  {name: "Development", value: 100}
]; */


const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (percent < 0.02) return ""; 
  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderColorfulLegendText = (value, entry) => {
  const { color, time = 0 } = entry;

  return (
  <>
    <span style={{paddingTop: 30, marginRight: 10}}>
      <span style={{position: 'relative', top: 2,  marginRight: 10, width: 14, height: 14, backgroundColor: color, display: 'inline-block' }}></span>
      <span style={{ color }}>{value}</span>
    </span>
    <span style={{float: 'right'}}>{time}</span>
  </>
  );
};

class Chart extends PureComponent {
  render() {
    const {data = []} = this.props;

    const payload = data.map(item => ({
        value : item.name,
        color : colorsMapping[item.name],
        type: 'square',
        time : secondsToString(item.value)
    }));

    return (
      <ResponsiveContainer width="100%" height={200}>
        <PieChart width={300} height={300}>
          <Legend payload={payload} iconSize = {0} formatter={renderColorfulLegendText} layout="vertical" align="left" verticalAlign="middle"/>        
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            innerRadius={30}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((item, index) => (
              <Cell key={`cell-${index}`} fill={colorsMapping[item.name]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

const DashboardChart = (props) => {
  const [items, setItems] = useState([]); 

  const {currentComputer, uid, week, lastUpdate, dates} = props;

    const date = moment(props.date).format('YYMMDD');  

    const startDate = moment(props.date).startOf('isoWeek');
    const days = !week ? [date] :  Array.from({ length: 7 }, (_, i) => (moment(startDate).add(i, 'days').format('YYMMDD'))); 

    useEffect(() => {
        if (!currentComputer) return;        
        const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/applications`);

        days.forEach(date => {   
          const dateExists = dates.some(d => moment(d).format('YYMMDD') === date);                
          if (dateExists) {              
            ref.child(date).once('value', 
                (snapshot) => { // success
                    const items = Object.values(snapshot.val() || {});
                    items.forEach(item => {
                        const category = appToCategory(item.name);
                        item.name = category;
                        item.value = Number(item.time);
                    });

                    setItems(prevItems => ({ ...prevItems, [date]: items }));
                },        
                (error) => { // error
                    console.log(error);
                }
            );
          }
        });
    }, [uid, currentComputer, lastUpdate]);  

    useEffect(() => {
        if (!currentComputer) return;
        const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/websites/`);
        days.forEach(date => {  
          const dateExists = dates.some(d => moment(d).format('YYMMDD') === date);                
          if (dateExists) {  
            ref.child(date).once('value', 
                (snapshot) => { // success
                    const items = Object.values(snapshot.val() || {});
                    items.forEach(item => {
                        const category = webToCategory(item.name);
                        item.name = category;
                        item.value = Number(item.time);
                    });                
                    setItems(prevItems => ({ ...prevItems, [date + 'W']: items }));
                },        
                (error) => { // error
                    console.log(error);
                }
            );
          }
        });
    }, [uid, currentComputer, lastUpdate]);  

    const groupedData = _.groupBy(_.flatten(Object.values(items)), 'name');
    
    const itemsWeek = _.map(groupedData, (items, name) => ({
      name,
      value: _.sumBy(items, item => Number(item.time))
    }));  
    
    const data = itemsWeek.filter(item => item.name !== 'Browsers');    
    data.sort((a,b) => b.value > a.value);    
  
    return (
        <div style={{marginRight: 50}}>
            <h3>Activity</h3>
            <div className="app-chart">
                {data.length > 0 ? <Chart data={data}/> : <Message style={{ marginBottom: 30}} warning content="No activity for this date" />}
            </div>            
        </div>
    );
};

export default withUID(DashboardChart);
