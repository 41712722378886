import React, { Component } from 'react'
import firebase from '../../firebase';
import ReportTable from '../UI/ReportTable';
import {Table} from 'semantic-ui-react';
import moment from 'moment';
import WebIcon from '../UI/WebIcon';
import withUID from './../withUID';
import ClearLogsButton from '../UI/ClearLogsButton';

const ApplicationItem = (props) => {
    const {website, query, url, time} = props.item;     
    const link = /^(f|ht)tps?:\/\//i.test(url) ? url : 'http://' + url;     	
	return (
        <>        
        <Table.Cell><WebIcon name={`http://${website.toLowerCase()}.com`}>{website}</WebIcon></Table.Cell>
        <Table.Cell title={query} className="trunc query"><a href={link} rel="noopener noreferrer" target="_blank">{query}</a></Table.Cell>            
        <Table.Cell>{moment.utc(time, 'X').format("D MMM, h:mm a")}</Table.Cell>        
        </>
	)
}

const collumns = [    
    { title : 'Website', style : {width : "150px"}},
    { title : 'Query', style : {width : ""}},                                 
    { title : 'Time', style : {width : "140px"}},			
];

class Queries extends Component {
    state = {
        alerts : [], // the current report       
        start : moment(), // the date of the current report
        last : undefined, // the date of the last report      
        reportRef : null, // ref to firebase {applications}        
    }

    handleClearLogs = () => {
        if (this.state.reportRef) this.state.reportRef.remove();         
        this.setState({alerts : []}); 
    }

    getAlerts = (reportRef) => {       
        if (reportRef) {        
            reportRef.on('value',
            // success
            (snapshot) => {
                const alerts = Object.values(snapshot.val() || {});  
                alerts.sort((a, b) => {
                    return b.time - a.time
                });
                this.setState({alerts})            
            },
            // error
            (error) => {
                console.log(error);            
            });
        }     
    }
    
    componentDidMount() {
        const {currentComputer, uid} = this.props;
        if (!currentComputer) return;        
        const reportRef = firebase.database().ref(`${uid}/${currentComputer}/reports/queries`);                    
        this.setState({reportRef});               
        this.getAlerts(reportRef);                         
    }     

    componentWillUnmount() {
        if (this.state.reportRef) this.state.reportRef.off();    
    }     

    render() {			
        const chart = this.state.alerts || [];        
        return (
            <>
                <ClearLogsButton onClick={this.handleClearLogs}/>
				<h2>Search Queries</h2>	                
    		    <div style={{padding: 10, minWidth : 600}}>	                    				    
            	    <ReportTable unstackable collumns={collumns} list={chart}>
            		    { (index, item) => <ApplicationItem index={index} item={item} />}
            	    </ReportTable>
        	    </div>                
            </>
        )
    }
}

export default withUID(Queries);
